var Schedule = {"January 1":"Matthew+1",
"January 2":"Matthew+2",
"December 31":"John+11", //test case
"January 3":"Matthew+3",
"January 4":"Matthew+4",
"January 5":"Matthew+5",
"January 8":"Matthew+6",
"January 9":"Matthew+7",
"January 10":"Matthew+8",
"January 11":"Matthew+9",
"January 12":"Matthew+10",
"January 15":"Matthew+11",
"January 16":"Matthew+12",
"January 17":"Matthew+13",
"January 18":"Matthew+14",
"January 19":"Matthew+15",
"January 22":"Matthew+16",
"January 23":"Matthew+17",
"January 24":"Matthew+18",
"January 25":"Matthew+19",
"January 26":"Matthew+20",
"January 29":"Matthew+21",
"January 30":"Matthew+22",
"January 31":"Matthew+23",
"February 1":"Matthew+24",
"February 2":"Matthew+25",
"February 5":"Matthew+26",
"February 6":"Matthew+27",
"February 7":"Matthew+28",
"February 8":"Mark+1",
"February 9":"Mark+2",
"February 12":"Mark+3",
"February 13":"Mark+4",
"February 14":"Mark+5",
"February 15":"Mark+6",
"February 16":"Mark+7",
"February 19":"Mark+8",
"February 20":"Mark+9",
"February 21":"Mark+10",
"February 22":"Mark+11",
"February 23":"Mark+12",
"February 26":"Mark+13",
"February 27":"Mark+14",
"February 28":"Mark+15",
"February 29":"Mark+16",
"March 1":"Mark+15",
"March 2":"Mark+16",
"March 3":"Luke+1",
"March 6":"Luke+2",
"March 7":"Luke+3",
"March 8":"Luke+4",
"March 9":"Luke+5",
"March 10":"Luke+6",
"March 13":"Luke+7",
"March 14":"Luke+8",
"March 15":"Luke+9",
"March 16":"Luke+10",
"March 17":"Luke+11",
"March 20":"Luke+12",
"March 21":"Luke+13",
"March 22":"Luke+14",
"March 23":"Luke+15",
"March 24":"Luke+16",
"March 27":"Luke+17",
"March 28":"Luke+18",
"March 29":"Luke+19",
"March 30":"Luke+20",
"March 31":"Luke+21",
"April 3":"Luke+22",
"April 4":"Luke+23",
"April 5":"Luke+24",
"April 6":"John+1",
"April 7":"John+2",
"April 10":"John+3",
"April 11":"John+4",
"April 12":"John+5",
"April 13":"John+6",
"April 14":"John+7",
"April 17":"John+8",
"April 18":"John+9",
"April 19":"John+10",
"April 20":"John+11",
"April 21":"John+12",
"April 24":"John+13",
"April 25":"John+14",
"April 26":"John+15",
"April 27":"John+16",
"April 28":"John+17",
"May 1":"John+18",
"May 2":"John+19",
"May 3":"John+20",
"May 4":"John+21",
"May 5":"Acts+1",
"May 8":"Acts+2",
"May 9":"Acts+3",
"May 10":"Acts+4",
"May 11":"Acts+5",
"May 12":"Acts+6",
"May 15":"Acts+7",
"May 16":"Acts+8",
"May 17":"Acts+9",
"May 18":"Acts+10",
"May 19":"Acts+11",
"May 22":"Acts+12",
"May 23":"Acts+13",
"May 24":"Acts+14",
"May 25":"Acts+15",
"May 26":"Acts+16",
"May 29":"Acts+17",
"May 30":"Acts+18",
"May 31":"Acts+19",
"June 1":"Acts+20",
"June 2":"Acts+21",
"June 5":"Acts+22",
"June 6":"Acts+23",
"June 7":"Acts+24",
"June 8":"Acts+25",
"June 9":"Acts+26",
"June 12":"Acts+27",
"June 13":"Acts+28",
"June 14":"Romans+1",
"June 15":"Romans+2",
"June 16":"Romans+3",
"June 19":"Romans+4",
"June 20":"Romans+5",
"June 21":"Romans+6",
"June 22":"Romans+7",
"June 23":"Romans+8",
"June 26":"Romans+9",
"June 27":"Romans+10",
"June 28":"Romans+11",
"June 29":"Romans+12",
"June 30":"Romans+13",
"July 3":"Romans+14",
"July 4":"Romans+15",
"July 5":"Romans+16",
"July 6":"1 Corinthians+1",
"July 7":"1 Corinthians+2",
"July 10":"1 Corinthians+3",
"July 11":"1 Corinthians+4",
"July 12":"1 Corinthians+5",
"July 13":"1 Corinthians+6",
"July 14":"1 Corinthians+7",
"July 17":"1 Corinthians+8",
"July 18":"1 Corinthians+9",
"July 19":"1 Corinthians+10",
"July 20":"1 Corinthians+11",
"July 21":"1 Corinthians+12",
"July 24":"1 Corinthians+13",
"July 25":"1 Corinthians+14",
"July 26":"1 Corinthians+15",
"July 27":"1 Corinthians+16",
"July 28":"2 Corinthians+1",
"July 31":"2 Corinthians+2",
"August 1":"2 Corinthians+3",
"August 2":"2 Corinthians+4",
"August 3":"2 Corinthians+5",
"August 4":"2 Corinthians+6",
"August 7":"2 Corinthians+7",
"August 8":"2 Corinthians+8",
"August 9":"2 Corinthians+9",
"August 10":"2 Corinthians+10",
"August 11":"2 Corinthians+11",
"August 14":"2 Corinthians+12",
"August 15":"2 Corinthians+13",
"August 16":"Galations+1",
"August 17":"Galations+2",
"August 18":"Galations+3",
"August 21":"Galations+4",
"August 22":"Galations+5",
"August 23":"Galations+6",
"August 24":"Ephesians+1",
"August 25":"Ephesians+2",
"August 28":"Ephesians+3",
"August 29":"Ephesians+4",
"August 30":"Ephesians+5",
"August 31":"Ephesians+6",
"September 1":"Philippians+1",
"September 4":"Philippians+2",
"September 5":"Philippians+3",
"September 6":"Philippians+4",
"September 7":"Colossians+1",
"September 8":"Colossians+2",
"September 11":"Colossians+3",
"September 12":"Colossians+4",
"September 13":"1 Thessalonians+1",
"September 14":"1 Thessalonians+2",
"September 15":"1 Thessalonians+3",
"September 18":"1 Thessalonians+4",
"September 19":"1 Thessalonians+5",
"September 20":"2 Thessalonians+1",
"September 21":"2 Thessalonians+2",
"September 22":"2 Thessalonians+3",
"September 25":"1 Timothy+1",
"September 26":"1 Timothy+2",
"September 27":"1 Timothy+3",
"September 28":"1 Timothy+4",
"September 29":"1 Timothy+5",
"October 2":"1 Timothy+6",
"October 3":"2 Timothy+1",
"October 4":"2 Timothy+2",
"October 5":"2 Timothy+3",
"October 6":"2 Timothy+4",
"October 9":"Titus+1",
"October 10":"Titus+2",
"October 11":"Titus+3",
"October 12":"Philemon+1",
"October 13":"Hebrews+1",
"October 16":"Hebrews+2",
"October 17":"Hebrews+3",
"October 18":"Hebrews+4",
"October 19":"Hebrews+5",
"October 20":"Hebrews+6",
"October 23":"Hebrews+7",
"October 24":"Hebrews+8",
"October 25":"Hebrews+9",
"October 26":"Hebrews+10",
"October 27":"Hebrews+11",
"October 30":"Hebrews+12",
"October 31":"Hebrews+13",
"November 1":"James+1",
"November 2":"James+2",
"November 3":"James+3",
"November 6":"James+4",
"November 7":"James+5",
"November 8":"1 Peter+1",
"November 9":"1 Peter+2",
"November 10":"1 Peter+3",
"November 13":"1 Peter+4",
"November 14":"1 Peter+5",
"November 15":"2 Peter+1",
"November 16":"2 Peter+2",
"November 17":"2 Peter+3",
"November 20":"1 John+1",
"November 21":"1 John+2",
"November 22":"1 John+3",
"November 23":"1 John+4",
"November 24":"1 John+5",
"November 27":"2 John+1",
"November 28":"3 John+1",
"November 29":"Jude+1",
"November 30":"Revelation+1",
"December 1":"Revelation+2",
"December 4":"Revelation+3",
"December 5":"Revelation+4",
"December 6":"Revelation+5",
"December 7":"Revelation+6",
"December 8":"Revelation+7",
"December 11":"Revelation+8",
"December 12":"Revelation+9",
"December 13":"Revelation+10",
"December 14":"Revelation+11",
"December 15":"Revelation+12",
"December 18":"Revelation+13",
"December 19":"Revelation+14",
"December 20":"Revelation+15",
"December 21":"Revelation+16",
"December 22":"Revelation+17",
"December 25":"Revelation+18",
"December 26":"Revelation+19",
"December 27":"Revelation+20",
"December 28":"Revelation+21",
"December 29":"Revelation+22"};

export default Schedule;