import React from "react";
import ReactDOM from 'react-dom/client';

function Landing() {
  return (
    <div className="about">
      <div class="container">
            <h2>Welcome</h2>
            <p>Are you looking for a way to read the entire New Testament in one year? Our NT260 plan is perfect for you. With 260 chapters in the New Testament and about 260 working days in a year, this plan is designed to help you accomplish this goal. By committing to daily readings, you'll be able to work your way through the entire New Testament in just one year.</p>
            <h2>How it works:</h2>
            <li>1. Subscribe for the 2024 Daily Email Reminders</li>
            <li>2. Click on the link in your email reminder to visit NT260.com</li>
            <li>3. Read Today's Scheduled Chapter - Finish the entire New Testament in One Year!</li>
      </div>
    </div>
  )
}

export default Landing;
