import React from "react";

const foots = () => {
    return (
    <footer>
    <div class="text-center p-3">
        © 2024 Copyright:
        <a class="text-dark" href="https://www.nt260.com"> NT260.com</a>
    </div>
    </footer>
    )
};
export default foots